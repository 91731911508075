import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Template from "../../images/Orevan-Header.gif";
import { useNavigate } from "react-router-dom";

import { SignUpButton } from "../signUpButton/SignUpButton";
import axios from "axios";
import Select from "react-select";
// import { GoogleLogin } from "@react-oauth/google";
// import { jwtDecode } from "jwt-decode";

export function Login() {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/Home");
    }
  }, [token]);

  return (
    <>
      <div className="hamda ">
        <div className="app1 w-75 ">
          <div className="left  ">
            <img className="w-100" src={Template} alt="" />
          </div>
          <div className="right pt-3 w-100">
            <div className=" formBox">
              <h1 className="text-center">Orevan Portals</h1>
              <h5 className="text-center familyTitle mb-3">
                Are you part of Orevan's family?
              </h5>
              {error && (
                <div className="message alert alert-danger mt-3">
                  <strong>{error}</strong>
                </div>
              )}
              <SignUpButton  />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
