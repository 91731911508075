import React, { useEffect, useState } from "react";
import Diamond from "../../images/googleIcon.svg";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";

export function SignUpButton() {
  const [user, setUser] = useState("");
  const [token, setToken] = useState("");
  const [emailValid, setEmailValid] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token") && emailValid) {
      navigate("/Home");
    }
  }, [token, emailValid]);

  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  // const handleLogin = async (res) => {
  //   if (res.data && res.data.email.endsWith("@orevan.org")) {
  //     setUser(user);
  //     setEmailValid(true);

  //   } else {
  //     setEmailValid(false);
  //     alert(

  //       "Unauthorized email domain. Please use your official @orevan.org email."

  //     );

  //   }
  // };

  const postRequest = async (res) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `http://127.0.0.1:8000/api/users/login-with-google`,
      {
        name: res.data.name,
        email: res.data.email,
        password: generateRandomString(10),
      },
      config
    );
    if (data.authorization.token) {
      setToken(data.authorization.token);
    } else {
      alert("Invalid email or password");
    }
    localStorage.setItem("token", data.authorization.token);

  };

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
            },
          }
        );
        postRequest(res);
        if (codeResponse.access_token) {
          setToken(codeResponse.access_token);
        }

        if (res.data && res.data.email.endsWith("@orevan.org")) {
          localStorage.setItem("token", codeResponse.access_token);
          setUser(user);
          setEmailValid(true);
        } else {
          setEmailValid(false);
          alert(
            "Unauthorized email domain. Please use your official @orevan.org email."
          );
        }
      } catch (error) {
        alert(error)
      }
    },
  });

  return (
    <>
      <button 
        className="btn text-center text-dark btn-light border border-2 w-100 fw-bold googleButton"
        onClick={login}
      >
        <img className="px-1 " src={Diamond} alt="" /> Sign in with Google
      </button>
    </>
  );
}
