import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <GoogleOAuthProvider clientId="366878617916-co7kom5ggsqqu33c523lp8g5ivbrp6po.apps.googleusercontent.com"> */}
    {/* 43977379151-v2t7abfhrpgnjq9pu8cpftmle5m8cjam.apps.googleusercontent.com */}
    <GoogleOAuthProvider clientId="529784158899-8enqj1bbqu712reb6i4p08m7gaahqn86.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
